<template>
  <div class="app-home">
    <div class="app-home-head">
      TECH DOC. EDITOR Import
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div class="app-home-table">
      <div class="table-name">
        <span>Spec {{ labelText("list") }}</span>
        <el-button type="primary" @click="handleCoaClick">
          <template>
            <i class="el-icon-plus"></i>
            <span>COA</span>
          </template>
        </el-button>
        <el-button type="primary" @click="handleFileClick">
          <template>
            <i class="el-icon-document"></i>
            <span>Library</span>
          </template>
        </el-button>
        <el-button type="warning" @click="handleVerifyListClick">
          <template>
            <span>Verify List</span>
          </template>
        </el-button>
        <el-button
          type="primary"
          @click="addClick"
        >
          <template>
            <i class="el-icon-plus"></i>
            <span>New</span>
          </template>
        </el-button>
      </div>

      <!-- 搜索栏 -->
      <el-row style="padding: 10px">
        <el-col :span="6">
          <span>{{ labelText("product_name") }}：</span>
          <el-autocomplete
            clearable
            v-model="searchParams.productName"
            :fetch-suggestions="productNameSuggestionsSearch"
            :placeholder="labelText('please_input')"
            popper-class="select-option"
            :popper-append-to-body="false"
          ></el-autocomplete>
        </el-col>
        <el-col :span="6">
          <div>
            <span>{{ labelText("item_code") }}：</span>
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="searchParams.itemCode"
              :fetch-suggestions="itemCodeSuggestionsSearch"
              :placeholder="labelText('please_input')"
            ></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="9">
          <div style="margin-right: 20px">
            <el-radio-group
              v-model="dataType"
              size="medium"
              @change="dataTypeChange"
            >
              <el-radio-button label="Spec"></el-radio-button>
              <el-radio-button label="SDS"></el-radio-button>
              <el-radio-button label="Composition"></el-radio-button>
              <el-radio-button label="TPS"></el-radio-button>
              <el-radio-button label="Conversion"></el-radio-button>
              <el-radio-button label="TDS"></el-radio-button>
              <el-radio-button label="Test Method"></el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="3">
          <div>
            <el-button @click="handleSearch()" type="primary">Search</el-button>
            <el-button @click="resetHandle()">Reset</el-button>
          </div>
        </el-col>
      </el-row>

      <SpecList v-if="dataType == 'Spec'" ref="specListRef"></SpecList>
      <SdsList v-if="dataType == 'SDS'" ref="sdsListRef"></SdsList>
    </div>
  </div>
</template>

<script>
import SpecList from "./spec/Spec-List.vue";
import SdsList from "./sds/SdsList.vue";
import * as mappingUtils from "./spec/config/sdsMapping";
import * as keyConvertUtils from "./spec/config/jsonKeyConverter";
// import VerifyList from "../../VerifyList.vue";

export default {
  components: {
    SpecList,
    SdsList,
  },
  data() {
    return {
      language: "EN",
      dataType: "Spec",
      // 查询条件
      searchParams: {
        productName: "",
        itemCode: "",
      },
      // itemCode 列表数据
      itemCodeList: [],
    };
  },
  computed: {
    // 用户信息
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      localStorage.setItem("loginUserNameKey", userInfo.username);
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    },
    labelText() {
      // 获取标签文本
      return function (text) {
        if (this.language === "EN") {
          return keyConvertUtils.convertToTitleCase(text);
        }
        return mappingUtils.getValueByKey(text);
      };
    },
  },
  created() {
    let pathLanguage = 'EN';
    if(typeof(pathLanguage)!='undefined'){
      this.language = this.$route.query.language;
      pathLanguage = this.$route.query.language;
    }
    localStorage.setItem('language',pathLanguage)
    
    
    let dType = localStorage.getItem("dataType");
    if (typeof dType !== "undefined") {
      this.dataType = dType;
    }
  },
  mounted() {
    this.getSpecItemCodeListData();
  },
  methods: {
    addClick(){
      let currentType = this.dataType.toLocaleLowerCase()
      this.$router.push({ path: "detail",query:{type:currentType, isCopy: false } });
    },
    // 重置查询条件
    resetHandle() {
      this.searchParams.itemCode = "";
      this.searchParams.productName = "";
    },
    // productName搜索
    productNameSuggestionsSearch(queryString, cb) {
      let list = [];
      console.info("this.itemCodeList:", this.itemCodeList);
      this.itemCodeList.forEach((element) => {
        list.push({ value: element.productName });
      });
      let results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      cb(results);
    },
    // 加载 itemCode 数据
    getSpecItemCodeListData(params) {
      let refKey = "";
      if (this.dataType === "Spec") {
        refKey = "specListRef";
      } else if (this.dataType === "SDS") {
        refKey = "sdsListRef";
      }
      
      // 尝试从 localStorage 加载数据
      const storedData = localStorage.getItem(`${this.dataType}_itemCodeList`);
      const storedTime = localStorage.getItem(`${this.dataType}_itemCodeList_time`);
      const currentTime = new Date().getTime();
      
      if (storedData && storedTime && (currentTime - storedTime < 7 * 24 * 60 * 60 * 1000)) {
        // 如果数据存在且未过期，直接使用存储的数据
        this.itemCodeList = JSON.parse(storedData);
      } else {
        // 否则从组件中获取数据
        const refComponent = this.$refs[refKey];
        if (
          refComponent &&
          typeof refComponent.getItemCodeListData === "function"
        ) {
          refComponent.getItemCodeListData(params, (data) => {
            this.itemCodeList = data;
            // 存储数据到 localStorage，并设置有效期
            localStorage.setItem(`${this.dataType}_itemCodeList`, JSON.stringify(data));
            localStorage.setItem(`${this.dataType}_itemCodeList_time`, currentTime);
          });
        } else {
          console.warn(
            `Ref ${refKey} is not available or does not have getItemCodeListData method.`
          );
        }
      }
    },
    // itemCode搜索
    itemCodeSuggestionsSearch(itemCode, cb) {
      let list = [];
      this.itemCodeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      let results = itemCode ? list.filter(this.createFilter(itemCode)) : list;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    handleSearch() {
      const productName = this.searchParams.productName;
      const itemCode = this.searchParams.itemCode;
      if (this.dataType == "Spec") {
        this.$refs.specListRef.searchListData(productName, itemCode);
      } else if (this.dataType == "SDS") {
        this.$refs.sdsListRef.searchListData(productName, itemCode);
      }
    },
    handleCoaClick() {
      this.$router.push({ path: "new-coa"});
    },
    handleVerifyListClick() {
      this.$router.push({
        path: "new-verify-list",
        query: { verifyList:1 },
      });
    },
    userInfo() {},
    handleFileClick() {},
    // 数据类型切换
    dataTypeChange() {
      localStorage.setItem("dataType", this.dataType);
      this.handleSearch();
    },
    multipleSelection() {},
    addToLibrary() {},
  },
};
</script>
